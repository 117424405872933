import { Model } from "../Model";

export class Bandeira extends Model {

  private _credito: Boolean;
  private _debito: Boolean;

  constructor(id: number, attributes: any) {
    super(id);
    this.descricao = attributes.descricao;
    this.codigo = attributes.codigo;
    this.tipo = attributes.tipo || '*';
    this.parcelas = new Array<number>();
    this._credito = attributes.credito;
    this._debito = attributes.debito;
  }

  public get credito() : Boolean {
    return this._credito;
  }

  public set credito(credito: Boolean) {
    this._credito = credito;
    if (this.creditoChanged) {
      this.creditoChanged(credito);
    }
  }

  public get debito() : Boolean {
    return this._debito;
  }

  public set debito(debito: Boolean) {
    this._debito = debito;
    if (this.debitoChanged) {
      this.debitoChanged(debito);
    }
  }

  debitoChanged: Function | undefined;
  creditoChanged: Function | undefined;

  descricao: String;
  codigo: String;
  tipo: String;
  parcelas: Array<number>;

}
