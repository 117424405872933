import axios2 from "@/axios2";
import { Bandeira } from "@/code/models/payment/Bandeira";
import { PaymentMethod } from "@/code/models/payment/PaymentMethod";
import { BaseService } from "@/code/services/base/baseService";

export class PaymentMethodsService extends BaseService<PaymentMethod> {

  constructor() {
    super(PaymentMethod);
  }

  updatePaymentMethods(idAccount: number, json: string) {
    return new Promise((resolve, reject) => {
      axios2.put(`payment-plans/${idAccount}`, json)
      .then(
        (response: any) => {
          if (response.data && response.data.status === 'success') {
            resolve(true);
          } else {
            reject(false);
          }
        },
        (error: any) => {
          reject(false);
        }
      )
    })
  };

  getBandeiras(idAccount: number): Promise<Array<Bandeira>> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.get(`payment-plans/erp/${idAccount}`)
      .then(
        (response: any) => {
          const res = new Array<Bandeira>();
          if (response.data && response.data.data) {

            // resposta da API
            // -----------------
            // Codigo:25
            // Descricao:'MASTER POS'
            // DescricaoBandeira:'Master'
            // IdBandeira:3
            // QuantidadeParcelas:1
            // TipoTransacao:'D'

            response.data.data.forEach((el: any) => {
              const b = res.filter((x) => x.id === el.IdBandeira);
              let bandeira: Bandeira | null = null;
              if (b && b.length > 0) {
                bandeira = b[0];
              }
              if (el.IdBandeira > 0 && (bandeira === undefined || bandeira === null)) {
                bandeira = new Bandeira(parseInt(el.IdBandeira), { descricao: el.DescricaoBandeira, codigo: el.Codigo, tipo: el.TipoTransacao });
                res.push(bandeira);
              }
              if (bandeira && el.QuantidadeParcelas && !bandeira.parcelas.includes(el.QuantidadeParcelas)) {
                bandeira?.parcelas.push(el.QuantidadeParcelas);
              }
            });
          }
          const result = new Array<Bandeira>();
          res.forEach((ban) => {
            // if (ban.parcelas && ban.parcelas.length > 0) {
              result.push(ban);
            // }
          })
          result.forEach(b => {
            b.parcelas = b.parcelas.sort((first: number, second: number) => {
              return first - second;
            });
          })
          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }
    });
  }

  getUrl(): String {
    return "";
  }

}
