<template>
  <v-container
    id="accounts"
    fluid
    tag="section"
  >
  <ValidationObserver
      ref="formPaymentMethods"
      class="w-full"
      v-slot="{ pristine }">
      <form key="formPaymentMethods">
        <v-row no-gutters class="mt-12" v-if="!isEnablePayments">
          <div class="text-h4 text-error error align-center" style="margin: 20px auto">
            Este ERP não suporta forma de pagamento
          </div>
        </v-row>
        <v-row no-gutters class="mt-6" v-if="isEnablePayments">
          <v-col cols="12" sm="3">
            <v-list>
              <v-subheader>Formas de pagamento</v-subheader>
                <v-list-item-group
                    v-model="selectedPaymentMethod"
                    active-class=""
                  >
                    <v-list-item v-for="(item, i) in visiblePaymentMethods" :key="i">
                      <template v-if="item.isVisible()">
                        <v-list-item-icon>
                          <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                          <v-list-item-subtitle v-if="item.subtitle" v-text="item.subtitle"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="4" class="mt-6 px-4">
            <section v-if="selectedPaymentMethod === 0" key="dinheiro">
              <v-row no-gutters>
                <div class="text-h4 text-center w-full">
                  {{ paymentMethods[selectedPaymentMethod].text }} -
                  {{ paymentMethods[selectedPaymentMethod].config.nome }}
                </div>
              </v-row>
              <v-row no-gutters>
                <ValidationProvider
                  name="isDinheiroActive"
                  vid="isDinheiroActive"
                >
                  <v-checkbox
                    v-model="paymentMethods[selectedPaymentMethod].isActive"
                    label="Ativo"
                    color="primary"
                  ></v-checkbox>
                </ValidationProvider>
              </v-row>
            </section>
            <section v-else-if="selectedPaymentMethod === 1" key="visio-pay-tef">
              <v-row no-gutters>
                <div class="text-h4 text-center w-full">
                  {{ paymentMethods[selectedPaymentMethod].text }} -
                  {{ paymentMethods[selectedPaymentMethod].config.nome }}
                </div>
              </v-row>
              <v-row no-gutters>
                <ValidationProvider
                  name="isVisioPayActive"
                  vid="isVisioPayActive"
                >
                  <v-checkbox
                    v-model="paymentMethods[selectedPaymentMethod].isActive"
                    label="Ativo"
                    color="primary"
                  ></v-checkbox>
                </ValidationProvider>
              </v-row>
              <template v-if="paymentMethods[selectedPaymentMethod].isActive">
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-5">
                    <ValidationProvider
                      name="visioPayMaxParcelas"
                      vid="visioPayMaxParcelas"
                    >
                      <v-select
                        color="secondary"
                        item-color="secondary"
                        label="Número de parcelas"
                        :items="parcels"
                        v-model="paymentMethods[selectedPaymentMethod].config.max_parcelas"
                        item-text="description"
                        item-value="value"
                      >
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <ValidationProvider
                      name="visioPayIsDebito"
                      vid="visioPayIsDebito"
                    >
                      <v-checkbox
                        v-model="paymentMethods[selectedPaymentMethod].config.debito"
                        label="Débito"
                        color="primary"
                      ></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <ValidationProvider
                      name="visioPayIsCredito"
                      vid="visioPayIsCredito"
                    >
                      <v-checkbox
                        v-model="paymentMethods[selectedPaymentMethod].config.credito"
                        label="Crédito"
                        color="primary"
                      ></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-3">
                    <ValidationProvider
                      name="antecipacao"
                      vid="antecipacao"
                    >
                      <v-checkbox
                        v-model="paymentMethods[selectedPaymentMethod].config.antecipacao"
                        label="Antecipação"
                        color="primary"
                      ></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="3" class="pr-3" v-if="paymentMethods[selectedPaymentMethod].config.antecipacao">
                    <ValidationProvider
                      name="mdr"
                      vid="mdr"
                    >
                      <v-text-field
                        v-model="paymentMethods[selectedPaymentMethod].config.mdr"
                        label="% MDR"
                        type="number"
                        prepend-inner-icon="fas fa-percent"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="3" v-if="paymentMethods[selectedPaymentMethod].config.antecipacao">
                    <ValidationProvider
                      name="taxa"
                      vid="taxa"
                    >
                      <v-text-field
                        v-model="paymentMethods[selectedPaymentMethod].config.taxa"
                        label="% Taxa"
                        type="number"
                        prepend-inner-icon="fas fa-percent"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <ValidationProvider
                      name="visioPayBandeiras"
                      vid="visioPayBandeiras"
                    >
                      <v-data-table
                        :headers="headersBandeirasVisioPay"
                        :items="bandeirasVisioPay"
                        class="elevation-1"
                        item-key="id"
                        show-select
                        :items-per-page="30"
                        v-model="paymentMethods[selectedPaymentMethod].config.bandeiras"
                        :loading="loadingBandeiras"
                        loading-text="Carregando bandeiras..."
                      >
                      </v-data-table>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </template>
            </section>
            <section v-else-if="selectedPaymentMethod === 2 && isErpLinxOrSaaS" key="cartao-credito-linx">
              <v-row no-gutters>
                <div class="text-h4 text-center w-full">
                  {{ paymentMethods[selectedPaymentMethod].text }} -
                  {{ paymentMethods[selectedPaymentMethod].config.nome }}
                </div>
              </v-row>
              <v-row no-gutters>
                <ValidationProvider
                  name="isCartaoLinxAtivo"
                  vid="isCartaoLinxAtivo"
                >
                  <v-checkbox
                    v-model="paymentMethods[selectedPaymentMethod].isActive"
                    label="Ativo"
                    color="primary"
                  ></v-checkbox>
                </ValidationProvider>
              </v-row>
              <template v-if="paymentMethods[selectedPaymentMethod].isActive">
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <ValidationProvider
                      name="cartaoLinxBandeiras"
                      vid="cartaoLinxBandeiras"
                    >
                      <v-data-table
                        :headers="headersBandeirasCartaoCredito"
                        :items="bandeirasCartaoCredito"
                        class="elevation-1"
                        item-key="id"
                        show-select items-per-page="30"
                        v-model="paymentMethods[selectedPaymentMethod].config.bandeiras"
                        :loading="loadingBandeiras"
                        loading-text="Carregando bandeiras..."
                      >
                        <template v-slot:item.descricao="{ item }">
                          {{ item.descricao }} [{{ item.parcelas.join(', ') }}]
                        </template>
                        <template v-slot:item.debito="{ item }">
                          <v-simple-checkbox v-model="item.debito" />
                        </template>
                        <template v-slot:item.credito="{ item }">
                          <v-simple-checkbox v-model="item.credito" />
                        </template>
                        <template v-slot:header.credito="{ on , props }">
                          <v-checkbox v-model="checkAllCredito" label="Crédito"/>
                        </template>
                        <template v-slot:header.debito="{ on , props }">
                          <v-checkbox v-model="checkAllDebito" label="Débito"/>
                        </template>
                      </v-data-table>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </template>
            </section>
            <section v-else-if="selectedPaymentMethod === 2 && !isErpLinxOrSaaS" key="cartao-credito-linx">
              <v-row no-gutters>
                <div class="text-h4 text-center w-full">
                  {{ paymentMethods[selectedPaymentMethod].text }} -
                  {{ paymentMethods[selectedPaymentMethod].config.nome }}
                </div>
              </v-row>
              <v-row no-gutters>
                <ValidationProvider
                  name="isCartaoLinxAtivo"
                  vid="isCartaoLinxAtivo"
                >
                  <v-checkbox
                    v-model="paymentMethods[selectedPaymentMethod].isActive"
                    label="Ativo"
                    color="primary"
                  ></v-checkbox>
                </ValidationProvider>
              </v-row>
              <template v-if="paymentMethods[selectedPaymentMethod].isActive">
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="bandeiraNome"
                      label="Bandeira"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="bandeiraCodigo"
                      label="Código"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="4">
                    <v-text-field
                      type="number"
                      v-model="bandeiraMinParcelas"
                      label="Mín. Parcelas"
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="4">
                    <v-text-field
                      type="number"
                      v-model="bandeiraMaxParcelas"
                      label="Max. Parcelas"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-btn color="success" @click="addPaymentMethod" block>
                      <i class="fas fa-plus font-size-18 mr-3"></i>
                      Adicionar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <ValidationProvider
                      name="cartaoLinxBandeiras"
                      vid="cartaoLinxBandeiras"
                    >
                      <v-data-table
                        :headers="headersBandeirasCartaoCredito"
                        :items="bandeirasCartaoCredito"
                        class="elevation-1"
                        item-key="id"
                        show-select :items-per-page="30"
                        v-model="paymentMethods[selectedPaymentMethod].config.bandeiras"
                        :loading="loadingBandeiras"
                        loading-text="Carregando bandeiras..."
                      >
                        <template v-slot:item.descricao="{ item }">
                          {{ item.descricao }} [{{ item.parcelas.join(', ') }}]
                        </template>
                        <template v-slot:item.debito="{ item }">
                          <v-simple-checkbox v-model="item.debito" />
                        </template>
                        <template v-slot:item.credito="{ item }">
                          <v-simple-checkbox v-model="item.credito" />
                        </template>
                        <template v-slot:header.credito="{ on , props }">
                          <v-checkbox v-model="checkAllCredito" label="Crédito"/>
                        </template>
                        <template v-slot:header.debito="{ on , props }">
                          <v-checkbox v-model="checkAllDebito" label="Débito"/>
                        </template>
                      </v-data-table>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </template>
            </section>
            <section v-else>
              <div class="text-h4 text-error">
                Selecione uma forma de pagamento para configurar
              </div>
            </section>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="2">
            <v-btn @click.native="previousTab" block>
              Anterior
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" offset="8">
            <v-btn @click.native="saveAndNextTab" block class="wizard-footer-right finish-button" color="primary">
                Próximo
            </v-btn>
          </v-col>
        </v-row>
      </form>
  </ValidationObserver>
  </v-container>
</template>

<script>

import Swal from 'sweetalert2';
import { PaymentMethodsService } from '@/code/services/payment/paymentMethodsService';
import { EventBus } from '@/code/util/eventBus';
import { Loading } from '@/code/util/swalUtil';
import { AccountService } from '@/code/services/user/accountService';
import { Account } from '@/code/models/User/Account';
import {ALLOW_ERP_INTEGRATIONS, ALLOW_ERP_PAYMENTS, ALLOW_LOAD_ERP_PAYMENTS, EErpTypes} from '@/code/enums/EErpTypes';
import { Bandeira } from '@/code/models/payment/Bandeira';

export default {

  data() {
    return {
      needsSave: false,
      idAccount: 0,
      isActive: false,
      accountLoaded: false,
      bandeirasLoaded: false,
      account: Account,
      checkAllDebito: false,
      checkAllCredito: false,
      isByChangedOnBandeira: false,
      paymentMethods: [
        { text: 'Dinheiro', isActive: false, isVisible: () => true, icon: 'fas fa-money-bill-wave', config: {
            key: "BRL",
            nome: "Pagamento em dinheiro",
            debito: false,
            credito: false,
            max_parcelas: null,
            bandeiras: [],
            mdr: 0,
            taxa: 0,
            antecipacao: false
          }
        },
        { text: 'VISIO Pay TEF', isActive: false, isVisible: () => true, icon: 'fas fa-credit-card', config: {
            key: "MDO_PAY",
            nome: "Pagamento TEF",
            debito: false,
            credito: false,
            max_parcelas: null,
            bandeiras: [],
            mdr: 0,
            taxa: 0,
            antecipacao: false
          }
        },
        { text: 'Cartão crédito ERP', isActive: false, isVisible: () => true, icon: 'fas fa-credit-card', config: {
            key: "CARTAO",
            nome: "Pagamento cartão",
            debito: false,
            credito: false,
            max_parcelas: null,
            bandeiras: [],
            mdr: 0,
            taxa: 0,
            antecipacao: false
          }
        }
      ],
      bandeirasCartaoCredito: [],
      bandeirasVisioPay: [],
      loadingBandeiras: false,
      selectedPaymentMethod: -1,
      parcels: [],
      filterTextBandeirasVisioPay: '',
      headersBandeirasCartaoCredito: [
        {
          text: 'ID',
          value: 'id',
          filterable: true
        },
        {
          text: 'Tipo',
          value: 'tipo',
          filterable: true
        },
        {
          text: 'Bandeira',
          value: 'descricao',
          filterable: true
        },
        {
          text: "Crédito",
          value: "credito",
          filterable: false,
          sortable: false
        },
        {
          text: "Débito",
          value: "debito",
          filterable: false,
          sortable: false
        }
      ],
      headersBandeirasVisioPay: [
        {
          text: 'ID',
          value: 'id',
          filterable: true
        },
        {
          text: 'Bandeira',
          value: 'descricao',
          filterable: true
        }
      ],
      bandeiraNome: '',
      bandeiraCodigo: '',
      bandeiraMinParcelas: 1,
      bandeiraMaxParcelas: 1
    }
  },

  mounted() {
    this.idAccount = this.$route.params.id;
    const that = this;
    if (this.idAccount && this.idAccount > 0) {
      this.createParcels();
      this.loadAccount();
    } else {
      Swal.fire({
        title: 'Parâmetro inválido',
        text: 'A loja informada é invalida!',
        icon: 'error'
      })
      .then(
        (result) => {
          that.$router.replace({ name: 'assistentAction' });
        }
      )
    }
  },

  methods: {
    loadAccount: async function() {
      const accountService = new AccountService();
      this.account = await accountService.get(this.idAccount, true);
      //this.accountLoaded = true;

      this.loadBandeiras();

      //this.preparePaymentMethods();
    },
    preparePaymentMethods: function() {
      // if (!this.accountLoaded || !this.bandeirasLoaded) {
      //   return;
      // }
      this.account.detail.payments_type.forEach((el) => {
        let methods = this.paymentMethods.filter((method) => {
          return method.config.key === el.tipo;
        });
        if (methods && methods.length > 0) {
          let pm = methods[0];
          pm.isActive = true;
          pm.config.debito = el.debito;
          pm.config.credito = el.credito;
          if (el.max_parcelas) {
            pm.config.max_parcelas = el.max_parcelas;
          }
          if (el.bandeiras) {
            const bandeirasSelecionadas = [];
            el.bandeiras.forEach((b) => {
              if (this.account.erp === EErpTypes.LINX || this.account.erp === EErpTypes.SAAS || this.account.erp === EErpTypes.VIXEN) {
                const filtradas = this.bandeirasCartaoCredito.filter((band) => band.id == b.id);
                filtradas.forEach((bf) => {
                  bf.credito = b.credito;
                  bf.debito = b.debito;
                  bf.debitoChanged = this.bandeiraDebitoChanged;
                  bf.creditoChanged = this.bandeiraCreditoChanged;
                  bandeirasSelecionadas.push(bf);
                });
              } else {
                let bandeira = new Bandeira(b.id, { codigo: b.id, descricao: b.label, debito: b.debito, credito: b.credito });
                bandeira.debitoChanged = this.bandeiraDebitoChanged;
                bandeira.creditoChanged = this.bandeiraCreditoChanged;

                bandeira.parcelas = b.parcelas;
                bandeirasSelecionadas.push(bandeira);
                this.bandeirasCartaoCredito.push(bandeira);
              }
            })
            pm.config.bandeiras = bandeirasSelecionadas;
          }
          pm.config.mdr = el.mdr ?? 0;
          pm.config.taxa = el.taxa ?? 0;
          pm.config.antecipacao = el.antecipacao ?? false;
        }
      })

      this.verifyAllCreditoChecked();
      this.verifyAllDebitoChecked();
    },
    createParcels: function() {
      this.parcels = [];
      for (let p = 1; p <= 12; p++) {
        this.parcels.push({
          description: `${p}X`,
          value: p
        });
      }
    },
    loadBandeiras: async function() {
      this.bandeirasCartaoCredito = [];
      this.bandeirasVisioPay = [];
      if (ALLOW_LOAD_ERP_PAYMENTS.includes(this.account.erp)) {
        this.loadingBandeiras = true;
        const service = new PaymentMethodsService();
        let bandeiras = await service.getBandeiras(this.idAccount);
        this.bandeirasCartaoCredito = bandeiras;
        this.bandeirasVisioPay = bandeiras;

        this.bandeirasCartaoCredito.forEach(b => {
          b.debitoChanged = this.bandeiraDebitoChanged;
        });

        this.bandeirasCartaoCredito.forEach(b => {
          b.creditoChanged = this.bandeiraCreditoChanged;
        });

        this.verifyAllCreditoChecked();
        this.verifyAllDebitoChecked();

        this.bandeirasVisioPay = bandeiras;
        this.loadingBandeiras = false;
        this.bandeirasLoaded = true;
      }
      this.preparePaymentMethods();
    },
    bandeiraDebitoChanged: function(newValue) {
      this.needsSave = true;
      this.verifyAllDebitoChecked();
    },
    bandeiraCreditoChanged: function(newValue) {
      this.needsSave = true;
      this.verifyAllCreditoChecked();
    },
    verifyAllDebitoChecked() {
      var withoutDebito = this.bandeirasCartaoCredito.filter(b => !b.debito);
      if (withoutDebito.length == 0 && !this.checkAllDebito) {
        this.isByChangedOnBandeira = true;
        this.checkAllDebito = true;
      }
      else if (withoutDebito.length > 0 && this.checkAllDebito) {
        this.isByChangedOnBandeira = true;
        this.checkAllDebito = false;
      }
    },
    verifyAllCreditoChecked() {
      var withoutCredito = this.bandeirasCartaoCredito.filter(b => !b.credito);
      if (withoutCredito.length == 0 && !this.checkAllCredito) {
        this.isByChangedOnBandeira = true;
        this.checkAllCredito = true;
      }
      else if (withoutCredito.length > 0 && this.checkAllCredito) {
        this.isByChangedOnBandeira = true;
        this.checkAllCredito = false;
      }
    },
    previousTab: async function() {
      const dirty = await this.$refs.formPaymentMethods.flags.dirty;
      if (dirty && this.needsSave) {
        Confirm.fire('Os dados informados serão perdidos. Deseja voltar à etapa anterior?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('previousAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('previousAssistentRoute');
      }
    },
    cartaoCreditoLinxVisible: function () {
      return this.account && (this.account.erp === EErpTypes.LINX || this.account.erp === EErpTypes.SAAS || this.account.erp === EErpTypes.VIXEN);
    },
    saveAndNextTab: async function() {
      const dirty = await this.$refs.formPaymentMethods.flags.dirty;
      if (this.needsSave || dirty) {
        Loading.fire("Salvando formas de pagamento!");
        const selectedMethods = { payments_type: [] };
        this.paymentMethods.forEach(pm => {
          if (pm.isActive) {
            let novo = {
              tipo: pm.config.key,
              nome: pm.config.nome,
              max_parcelas: pm.config.max_parcelas,
              bandeiras: []
            };
            if (pm.config.debito !== undefined) {
              novo.debito = pm.config.debito;
            }
            if (pm.config.debito !== undefined) {
              novo.credito = pm.config.credito;
            }
            if (pm.config.antecipacao || pm.config.key === "MDO_PAY") {
              novo.antecipacao = pm.config.antecipacao;
              novo.mdr = parseFloat(pm.config.mdr);
              novo.taxa = parseFloat(pm.config.taxa);
            }
            if (pm.config.bandeiras && pm.config.bandeiras.length > 0) {
              pm.config.bandeiras.forEach(b => {
                const bandeira = {
                  id: this.isErpLinxOrSaaS ? b.id.toString() : b.codigo.toString(),
                  label: b.descricao
                };

                bandeira.credito = b.credito ? true : false;
                bandeira.debito = b.debito ? true : false;

                if (pm.config.key === 'CARTAO') {
                  bandeira.parcelas = b.parcelas;
                }
                novo.bandeiras.push(bandeira);
              });
            }

            selectedMethods.payments_type.push(novo);
          }
        });

        var json = JSON.stringify(selectedMethods);
        const service = new PaymentMethodsService();
        if (await service.updatePaymentMethods(this.idAccount, json)) {
          Swal.close();
          EventBus.$emit('nextAssistentRoute');
        }
      } else {
        EventBus.$emit('nextAssistentRoute');
      }
    },
    addPaymentMethod: function() {
      if (!this.bandeiraNome || !this.bandeiraCodigo || !this.bandeiraMinParcelas || !this.bandeiraMaxParcelas) {
        Swal.fire({
          title: 'Campos obrigatórios',
          text: 'É necessario preencher todos os campos',
          icon: 'error'
        });
      } else if (parseInt(this.bandeiraMinParcelas) > parseInt(this.bandeiraMaxParcelas)) {
        Swal.fire({
          title: 'Máximo de parcelas',
          text: 'O máximo de parcelas deve ser maior que o mínimo de parcelas',
          icon: 'error'
        });
      } else {
        var bandeiraPorCodigo = this.bandeirasCartaoCredito.filter(el => el.codigo === this.bandeiraCodigo);
        if (bandeiraPorCodigo && bandeiraPorCodigo.length > 0) {
          Swal.fire({
            title: 'Código já cadastrado',
            text: 'O código da bandeira já foi cadastrado',
            icon: 'error'
          });
        } else {
          let bandeiraNova = new Bandeira(0, {descricao: this.bandeiraNome, codigo: this.bandeiraCodigo, credito: true, debito: true});
          for (let p = parseInt(this.bandeiraMinParcelas); p <= parseInt(this.bandeiraMaxParcelas); p++) {
            bandeiraNova.parcelas.push(p);
          }
          this.bandeirasCartaoCredito.push(bandeiraNova);
          if (!this.paymentMethods[this.selectedPaymentMethod].config.bandeiras) {
            this.paymentMethods[this.selectedPaymentMethod].config.bandeiras = [];
          }
          this.paymentMethods[this.selectedPaymentMethod].config.bandeiras.push(bandeiraNova);

          this.bandeiraNome = '';
          this.bandeiraCodigo = '';
          this.bandeiraMinParcelas = 1;
          this.bandeiraMaxParcelas = 1;
        }
      }
    }
  },
  computed: {
    visiblePaymentMethods: function() {
      return this.paymentMethods ? this.paymentMethods.filter((method) => method.isVisible()) : [];
    },
    isErpLinxOrSaaS: function () {
      return this.account.erp === EErpTypes.LINX || this.account.erp === EErpTypes.SAAS || this.account.erp === EErpTypes.VIXEN;
    },
    isEnablePayments: function() {
      return ALLOW_ERP_PAYMENTS.includes(this.account.erp);
    }
  },
  watch: {
    selectedPaymentMethod: async function() {
      const dirty = await this.$refs.formPaymentMethods.flags.dirty;
      this.needsSave = this.needsSave || dirty;
    },
    checkAllDebito: function(newValue) {
      if (!this.isByChangedOnBandeira) {
        this.bandeirasCartaoCredito?.forEach(b => b.debito = newValue);
      }
      this.isByChangedOnBandeira = false;
    },
    checkAllCredito: function(newValue) {
      if (!this.isByChangedOnBandeira) {
        this.bandeirasCartaoCredito?.forEach(b => b.credito = newValue);
      }
      this.isByChangedOnBandeira = false;
    }
  }

}
</script>
